import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const Callback = () => {
    const { user } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        const postUserData = async () => {
            try {
                console.log(`Posting user data... ${user.email} link ${process.env.REACT_APP_API_BASE_URL}/user`);
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        name: user.name, 
                        email: user.email,
                        username: user.nickname,
                        wallet: 0
                     }),
                });
                if (response.ok) {
                    console.log('Usuario creado');
                    navigate('/fixtures');
                } else {
                    console.log('Error al crear usuario');
                }
            } catch (error) {
                console.error('Error en la solicitud:', error);
            }
        };

        if (user) {
            postUserData();
        }
    }, [user, navigate]);

    return (
        <div>
            <h1>Callback</h1>
            <p>Posting user data...</p>
        </div>
    );
};

export default Callback;