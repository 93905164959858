import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // URL del backend

// Función para agregar fondos a la billetera
export const addFundsToWallet = async (userEmail, amount) => {
    try {
        console.log(`add userEmail: ${userEmail}, amount: ${amount}`);
        const response = await fetch(`${API_BASE_URL}/wallet`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: userEmail,
                value: amount
            }),
        });
        return response.data;
    } catch (error) {
        console.error("Error al agregar fondos", error);
        throw error;
    }
};

// función para obtener el saldo de la billetera del usuario
export const getWalletBalance = async (userEmail) => {
    try {
        console.log(`fetch ${API_BASE_URL}/user?email=${userEmail}`);
        const response = await axios.get(`${API_BASE_URL}/user?email=${userEmail}`);
        console.log(`get userEmail: ${userEmail}, data: ${response.data.wallet}`);
        return response.data.wallet;
    } catch (error) {
        console.error("Error al obtener el saldo de la billetera", error);
        throw error;
    }
};

export const getFixtureDetails = async (fixtureId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/fixtures/${fixtureId}`);
        return response.data;
    } catch (error) {
        console.error("Error al obtener detalles del partido", error);
        throw error;
    }
};

export const buyBono = async (userEmail, fixtureId, amount, cost, prediction) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/bonos`, {
            userEmail,
            fixtureId,
            amount,
            cost,
            prediction,
            paymentMethod: 'wallet', 
            wallet: true 
        });
        return response.data;
    } catch (error) {
        console.error("Error al comprar bono", error);
        throw error;
    }
};

export const getUserBonos = async (userEmail) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/bonos?email=${userEmail}`);
      return response.data;
    } catch (error) {
      console.error("Error al obtener los bonos del usuario", error);
      throw error;
    }
};

export const getRecommendedFixtures = async (userEmail) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/jobs?email=${userEmail}`);
        return response.data;
    } catch (error) {
        console.error("Error al obtener los partidos recomendados", error);
    }
};

export const getHeartBeat = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/heartbeat`);
        return response.data;
    } catch (error) {
        console.error("Error al obtener heartbeat", error);
    }
};

export const createWebPayTransaction = async (userEmail, fixtureId, amount, cost, prediction) => {
    try {
        console.log('Iniciando transacción WebPay');
        const response = await axios.post(`${API_BASE_URL}/bonos`, {
            userEmail,
            fixtureId,
            amount,
            cost,
            prediction,
            paymentMethod: 'webpay',
            wallet: false
        });

        // Si recibimos la URL y token de WebPay
        if (response.data.url && response.data.token) {
            // Crear y enviar formulario programáticamente
            const form = document.createElement('form');
            form.method = 'POST';
            form.action = response.data.url;

            const tokenInput = document.createElement('input');
            tokenInput.type = 'hidden';
            tokenInput.name = 'token_ws';
            tokenInput.value = response.data.token;

            form.appendChild(tokenInput);
            document.body.appendChild(form);
            form.submit();
        }

        return response.data;
    } catch (error) {
        console.error("Error creating WebPay transaction", error);
        throw error;
    }
};

// obtener estado de transacción WebPay
export const getWebPayTransactionStatus = async (token) => {
    try {
        console.log(`getWebPayTransactionStatus token: ${token}`);
        const response = await axios.post(`${API_BASE_URL}/webpay/confirm`, { token_ws: token });
        console.log('Respuesta de la transacción:', JSON.stringify(response.data));
        return response.data;
    } catch (error) {
        console.error("Error getting WebPay transaction status", error);
        throw error;
    }
};

export const createAuctionOffer = async (fixtureId, quantity) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/auctions/offer`, {
            fixtureId,
            quantity
        });
        return response.data;
    } catch (error) {
        console.error("Error al crear oferta de subasta", error);
        throw error;
    }
};

export const getAvailableAuctions = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/auctions/available`);
        return response.data;
    } catch (error) {
        console.error("Error al obtener subastas disponibles", error);
        throw error;
    }
};

export const createAuctionProposal = async (auctionId, quantity) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/auctions/proposal/${auctionId}`, {
            quantity
        });
        return response.data;
    } catch (error) {
        console.error("Error al crear propuesta de subasta", error);
        throw error;
    }
};

export const respondToAuctionProposal = async (auctionId, proposalId, accept) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/auctions/respond/${auctionId}/${proposalId}`, {
            accept
        });
        return response.data;
    } catch (error) {
        console.error("Error al responder a propuesta", error);
        throw error;
    }
};

export const getAdminDashboardData = async () => {
    try {
        const [availableAuctions, myProposals, myAuctions] = await Promise.all([
            axios.get(`${API_BASE_URL}/auctions/available`),
            axios.get(`${API_BASE_URL}/auctions/my-proposals`),
            axios.get(`${API_BASE_URL}/auctions/my-auctions`)
        ]);
        
        return {
            availableAuctions: availableAuctions.data,
            myProposals: myProposals.data,
            myAuctions: myAuctions.data
        };
    } catch (error) {
        console.error("Error al obtener datos del dashboard", error);
        throw error;
    }
};

// Función para agregar fondos a la billetera
export const updateAdminUser = async (userEmail, role) => {
    try {
        console.log(`userEmail: ${userEmail}, role: ${role}`);
        const response = await fetch(`${API_BASE_URL}/admin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: userEmail,
                value: role
            }),
        });
        return response.data;
    } catch (error) {
        console.error("Error al hacer admin", error);
        throw error;
    }
};

// Verificar si el usuario es admin
export const checkAdminStatus = async (userEmail) => {
    console.log(`checkAdminStatus userEmail: ${userEmail}`);
    try {
        const response = await axios.get(`${API_BASE_URL}/user?email=${userEmail}`);
        console.log(`checkAdminStatus userEmail: ${userEmail}, data: ${response.data.role}`);
        console.log(`checkAdminStatus userEmail: ${userEmail}, data: ${response.data.role === 'admin'}`);
        return response.data.role === 'admin';
    } catch (error) {
        console.error("Error verificando estado de admin", error);
        return false;
    }
};

export const getUser = async (userEmail) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/user?email=${userEmail}`);
        return response.data;
    } catch (error) {
        console.error("Error al obtener usuario", error);
        throw error;
    }
};