import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from '../components/context/AuthContext'; // Ajusta la ruta según tu estructura de archivos
import LoginButton from '../LoginButton';
import LogoutButton from '../LogoutButton';
import { updateAdminUser } from '../api/api'; 
const Home = () => {
  const { user, isAuthenticated } = useAuth0();
  const { isAdmin, setIsAdmin } = useAuth();

  const handleMakeAdmin = () => {
    // Lógica para hacer que el usuario sea administrador
    updateAdminUser(user.email, 'admin');
    setIsAdmin('admin');
  };

  const handleNotAdmin = () => {
    // Lógica para hacer que el usuario sea administrador
    updateAdminUser(user.email, 'user');
    setIsAdmin('user');
  };

  return (
    <div>
      <h1>Home</h1>
      {!isAuthenticated ? (
        <>
          <h2>Por favor, inicie sesión</h2>
          <LoginButton />
        </>
      ) : (
        <>
          <h2>Bienvenido, {user.name}!</h2>
          {isAdmin === 'admin' ? (
            <div>
              <p>Eres un administrador.</p>
              <button onClick={handleNotAdmin}>Dejar de ser administrador</button>
            </div>
          ) : (
            <button onClick={handleMakeAdmin}>Hacer administrador</button>
          )}
          <LogoutButton />
        </>
      )}
    </div>
  );
};

export default Home;
