import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getWebPayTransactionStatus } from '../api/api';

const WebPayReturn = () => {
  const [status, setStatus] = useState('processing');
  const [transactionData, setTransactionData] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  
  // Usar useMemo para queryParams
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  useEffect(() => {
    const processTransaction = async () => {
      try {
        const token_ws = queryParams.get('token_ws');
        const TBK_TOKEN = queryParams.get('TBK_TOKEN');

        console.log(`token_ws: ${token_ws}, TBK_TOKEN: ${TBK_TOKEN}`);
        
        if (TBK_TOKEN) {
          setStatus('aborted');
          return;
        }

        if (!token_ws) {
          setStatus('error');
          return;
        }

        console.log('Obteniendo estado de la transacción...');
        const response = await getWebPayTransactionStatus(token_ws);
        console.log('Respuesta de la transacción:', JSON.stringify(response.data));
        setTransactionData(response.data);
        
        if (response.status === 'SUCCESS') {
          setStatus('success');
        } else if (response.status === 'REJECTED') {
          setStatus('rejected');
        } else {
          setStatus('error');
        }
      } catch (error) {
        console.error('Error procesando transacción:', error);
        setStatus('error');
      }
    };

    processTransaction();
  }, [queryParams]);

  const renderContent = () => {
    switch (status) {
      case 'processing':
        return (
          <div className="text-center">
            <h2 className="text-xl font-bold">Procesando tu pago</h2>
            <p>Por favor espera mientras confirmamos tu transacción...</p>
            {/* Agregar un spinner o animación de carga */}
          </div>
        );

      case 'success':
        return (
          <div className="text-center text-green-600">
            <h2 className="text-2xl font-bold">¡Pago exitoso!</h2>
            <div className="mt-4">
              <p>Código de autorización: {transactionData?.authorization_code}</p>
              <p>Fecha: {transactionData?.transaction_date}</p>
              <p>Monto: ${transactionData?.amount}</p>
            </div>
            <button
              onClick={() => navigate('/bonos')}
              className="mt-4 bg-blue-500 text-white px-6 py-2 rounded-lg"
            >
              Ver mis bonos
            </button>
          </div>
        );

      case 'rejected':
        return (
          <div className="text-center text-red-600">
            <h2 className="text-2xl font-bold">Pago rechazado</h2>
            <p>Lo sentimos, tu pago no pudo ser procesado.</p>
            <button
              onClick={() => navigate('/')}
              className="mt-4 bg-blue-500 text-white px-6 py-2 rounded-lg"
            >
              Volver al inicio
            </button>
          </div>
        );

      case 'aborted':
        return (
          <div className="text-center text-yellow-600">
            <h2 className="text-2xl font-bold">Transacción cancelada</h2>
            <p>Has cancelado la transacción.</p>
            <button
              onClick={() => navigate('/')}
              className="mt-4 bg-blue-500 text-white px-6 py-2 rounded-lg"
            >
              Volver al inicio
            </button>
          </div>
        );

      case 'error':
        return (
          <div className="text-center text-red-600">
            <h2 className="text-2xl font-bold">Error en la transacción</h2>
            <p>Ha ocurrido un error al procesar tu pago.</p>
            <button
              onClick={() => navigate('/')}
              className="mt-4 bg-blue-500 text-white px-6 py-2 rounded-lg"
            >
              Volver al inicio
            </button>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto p-8">
      <div className="max-w-lg mx-auto bg-white p-6 rounded-lg shadow-lg">
        {renderContent()}
      </div>
    </div>
  );
};

export default WebPayReturn;