import React, { useEffect } from 'react';

const WebPayForm = ({ token, url }) => {
  useEffect(() => {
    // Enviar el formulario automáticamente cuando el componente se monte
    document.getElementById('webpayForm').submit();
  }, []);

  return (
    <div className="webpay-redirect">
      <form id="webpayForm" method="POST" action={url}>
        <input type="hidden" name="token_ws" value={token} />
        <div className="text-center">
          <p>Redirigiendo a WebPay...</p>
          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
            Click aquí si no eres redirigido automáticamente
          </button>
        </div>
      </form>
    </div>
  );
};

export default WebPayForm;