import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  checkAdminStatus,
  getAvailableAuctions,
  createAuctionOffer,
  createAuctionProposal,
  respondToAuctionProposal
} from '../api/api';

const AdminDashboard = () => {
  const { user, isAuthenticated } = useAuth0();
  const [isAdmin, setIsAdmin] = useState('admin');
  const [auctions, setAuctions] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAdmin = async () => {
      if (isAuthenticated && user?.email) {  // Verificamos que user.email exista
        const isUserAdmin = await checkAdminStatus(user.email);
        setIsAdmin(isUserAdmin);
        if (isUserAdmin) {
          loadAuctions();
        }
      }
    };
    checkAdmin();
  }, [isAuthenticated, user?.email]); // Añadimos user.email como dependencia

  const loadAuctions = async () => {
    try {
      const availableAuctions = await getAvailableAuctions();
      setAuctions(availableAuctions);
    } catch (error) {
      setError('Error cargando subastas');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateOffer = async (fixtureId, quantity) => {
    try {
      await createAuctionOffer(fixtureId, quantity);
      loadAuctions(); // Recargar subastas
    } catch (error) {
      setError('Error creando oferta');
    }
  };

  const handleCreateProposal = async (auctionId, quantity) => {
    try {
      await createAuctionProposal(auctionId, quantity);
      loadAuctions();
    } catch (error) {
      setError('Error creando propuesta');
    }
  };

  const handleRespond = async (auctionId, proposalId, accept) => {
    try {
      await respondToAuctionProposal(auctionId, proposalId, accept);
      loadAuctions();
    } catch (error) {
      setError('Error respondiendo a propuesta');
    }
  };

  if (!isAuthenticated) {
    return <div>Por favor, inicie sesión para continuar.</div>;
  }

  if (!isAdmin) {
    return <div>No tiene permisos de administrador.</div>;
  }

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Panel de Administración</h1>
      
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      {/* Sección para crear nueva oferta */}
      <section className="mb-8 bg-white p-4 rounded shadow">
        <h2 className="text-xl font-semibold mb-4">Crear Nueva Oferta</h2>
        <div className="grid grid-cols-2 gap-4">
          <input
            type="number"
            placeholder="ID del Partido"
            className="border p-2 rounded"
          />
          <input
            type="number"
            placeholder="Cantidad"
            className="border p-2 rounded"
          />
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            onClick={() => handleCreateOffer(/* valores */)}
          >
            Crear Oferta
          </button>
        </div>
      </section>

      {/* Lista de subastas activas */}
      <section className="bg-white p-4 rounded shadow">
        <h2 className="text-xl font-semibold mb-4">Subastas Activas</h2>
        <div className="grid gap-4">
          {auctions.map((auction) => (
            <div key={auction.id} className="border p-4 rounded">
              <h3>Subasta #{auction.id}</h3>
              <p>Partido: {auction.fixtureId}</p>
              <p>Cantidad: {auction.quantity}</p>
              <p>Estado: {auction.status}</p>
              <div className="mt-2">
                {auction.status === 'PENDING' && (
                  <div className="flex gap-2">
                    <button
                      className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                      onClick={() => handleRespond(auction.id, auction.proposalId, true)}
                    >
                      Aceptar
                    </button>
                    <button
                      className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                      onClick={() => handleRespond(auction.id, auction.proposalId, false)}
                    >
                      Rechazar
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default AdminDashboard;