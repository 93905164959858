import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { getFixtureDetails, buyBono, getWalletBalance, createWebPayTransaction, getUser } from '../api/api.js';
import WebPayForm from '../components/WebPayForm';

function FixtureDetails() {
  const { id } = useParams();
  const [fixture, setFixture] = useState(null);
  const [prediction, setPrediction] = useState('');
  const [amount, setAmount] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('wallet'); // Nuevo estado para el método de pago
  const { user, isAuthenticated } = useAuth0();
  const [homeTeamGoals, setHomeTeamGoals] = useState(0);
  const [awayTeamGoals, setAwayTeamGoals] = useState(0);
  const [referee, setReferee] = useState('Indefinido');
  const cost = process.env.BONO_COST || 1000;
  const [webpayData, setWebpayData] = useState(null);
  const [isAdmin, setIsAdmin] = useState('user');

  const fetchFixtureDetails = useCallback(async () => {
    try {
      const fixtureDetails = await getFixtureDetails(id);
      setFixture(fixtureDetails[0]);
      setHomeTeamGoals(fixtureDetails[0].homeTeamGoals ?? 0);
      setAwayTeamGoals(fixtureDetails[0].awayTeamGoals ?? 0);
      setReferee(fixtureDetails[0].referee ?? 'Indefinido');
    } catch (error) {
      console.error("Error fetching fixture details:", error);
    }
  }, [id]);
  
  const checkUserRole = useCallback(async () => {
    if (user?.email) {
      const response = await getUser(user.email);
      setIsAdmin(response.role);
    }
  }, [user?.email]);
  
  useEffect(() => {
    fetchFixtureDetails();
    checkUserRole();
  }, [fetchFixtureDetails, checkUserRole]);

  const handleBuyBono = async () => {
    if (!isAuthenticated) {
      alert("Por favor, inicia sesión para comprar bonos.");
      return;
    }
    if (!prediction || !amount) {
      alert("Por favor, selecciona una predicción y un monto.");
      return;
    }
    try {
      if (paymentMethod === 'wallet') {
        console.log(`email: ${user.email}, fix_id: ${id}, prediction: ${prediction}, parseFloat(amount): ${parseFloat(amount)}`);
        const walletBalance = await getWalletBalance(user.email);
        if (parseFloat(amount) * cost > walletBalance.balance) {
          alert("Saldo insuficiente. Por favor, recarga tu billetera.");
          return;
        }
        await buyBono(user.email, id, parseFloat(amount), cost, prediction);
        alert("Bono comprado exitosamente");
      } else {
        const response = await createWebPayTransaction(
        user.email,
        id,
        parseFloat(amount),
        cost,
        prediction
        );

        if (response.url && response.token) {
          setWebpayData(response);
        }
      }
      // Actualizar los detalles del fixture después de la compra
      const updatedFixture = await getFixtureDetails(id);
      setFixture(updatedFixture[0]);
    } catch (error) {
      alert("Error al comprar el bono. Por favor, intenta de nuevo.");
      console.error("Error comprando el bono:", error);
    }
  };

  const handleBuyAdminBono = async () => {
    if (!isAuthenticated) {
      alert("Por favor, inicia sesión para comprar bonos.");
      return;
    }
    if (!prediction || !amount) {
      alert("Por favor, selecciona una predicción y un monto.");
      return;
    }
    try {
      if (paymentMethod === 'wallet') {
        console.log(`email: ${user.email}, fix_id: ${id}, prediction: ${prediction}, parseFloat(amount): ${parseFloat(amount)}`);
        const walletBalance = await getWalletBalance(user.email);
        if (parseFloat(amount) * 0.9 * cost > walletBalance.balance) {
          alert("Saldo insuficiente. Por favor, recarga tu billetera.");
          return;
        }
        await buyBono(user.email, id, parseFloat(amount), 0.9 * cost, prediction);
        alert("Bono comprado exitosamente");
      } else {
        const response = await createWebPayTransaction(
        user.email,
        id,
        parseFloat(amount),
        cost,
        prediction
        );

        if (response.url && response.token) {
          setWebpayData(response);
        }
      }
      // Actualizar los detalles del fixture después de la compra
      const updatedFixture = await getFixtureDetails(id);
      setFixture(updatedFixture[0]);
    } catch (error) {
      alert("Error al comprar el bono. Por favor, intenta de nuevo.");
      console.error("Error comprando el bono:", error);
    }
  };

  // Mostrar formulario de WebPay si hay datos
  if (webpayData) {
    return <WebPayForm token={webpayData.token} url={webpayData.url} />;
  }

  if (!fixture) {
    return <div>Cargando detalles del partido...</div>;
  }

  return (
    <div>
      <h1>Detalles del Partido</h1>
      <p>Fecha: {fixture.date}</p>
      <p>Local: {fixture.homeTeamName}</p>
      <p>Visitante: {fixture.awayTeamName}</p>
      <p>Árbitro: {referee}</p>
      <p>Goles del local: {homeTeamGoals}</p>
      <p>Goles del visitante: {awayTeamGoals}</p>
      <p>Bonos disponibles: {fixture.availableBonds}</p>
      <p>Odds Local: {fixture.oddsName !== 'No odd' ? fixture.oddsValues[0].odd : 'No disponible'}</p>
      <p>Odds Empate: {fixture.oddsName !== 'No odd' ? fixture.oddsValues[1].odd : 'No disponible'}</p>
      <p>Odds Visita: {fixture.oddsName !== 'No odd' ? fixture.oddsValues[2].odd : 'No disponible'}</p>
      <p>Costo por Bono: {cost}</p>
      <p>Admin: {isAdmin}</p>

      {fixture.oddsName !== 'No odd' && <div>
        <h2>Comprar Bono</h2>
        <div>
          <label>Método de pago: </label>
          <select 
            value={paymentMethod} 
            onChange={(e) => setPaymentMethod(e.target.value)}
            className="mb-4 p-2 border rounded"
          >
            <option value="wallet">Billetera</option>
            <option value="webpay">WebPay</option>
          </select>
        </div>

        <select value={prediction} onChange={(e) => setPrediction(e.target.value)}>
          <option value="">Selecciona tu predicción</option>
          <option value="Home">Gana Local</option>
          <option value="Away">Gana Visitante</option>
          <option value="Draw">Empate</option>
        </select>

        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Nº de bonos a comprar"
        />

        {isAdmin === 'admin' && <button onClick={handleBuyAdminBono}>Comprar 10% off Admin</button>}

        <button 
          onClick={handleBuyBono}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          {paymentMethod === 'wallet' ? 'Comprar con Billetera' : 'Pagar con WebPay'}
        </button>
      </div>}
    </div>
  );
}

export default FixtureDetails;