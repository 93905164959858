import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './views/HomeScreen.js';
import Wallet from './views/WalletScreen.js';
import Navbar from './components/navbar/Navbar.js';
import Fixtures from './views/FixturesScreen.js';
import FixtureDetails from './views/FixturesDetail.js';
import BonosScreen from './views/BonosScreen.js';
import Callback from './views/Callback.js';
import Recommendations from './views/RecommendationScreen.js';
import WebPayReturn from './views/WebPayReturn';
import AdminDashboard from './views/AdminDashboard';
import { AuthProvider } from './components/context/AuthContext.js';

function App() {
  return (
    <AuthProvider>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/wallet" element={<Wallet />} /> 
        <Route path="/fixtures" element={<Fixtures />} />
        <Route path="/fixtures/:id" element={<FixtureDetails />} />
        <Route path="/bonos" element={<BonosScreen />} />
        <Route path="/callback" element={<Callback />} />
        <Route path="/recommendations" element={<Recommendations />} />
        <Route path="/webpay-return" element={<WebPayReturn />} />
        <Route path="/admin" element={<AdminDashboard />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;

